<template>
    <defaultSec :title="'案例'">
        <keep-alive>
            <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
                <el-form-item label="案例名称">
                    <el-input v-model="formParam.experienceName" placeholder="请输入案例名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="案例类型">
                    <el-select v-model="formParam.type" filterable clearable
                               placeholder="案例类型">
                        <el-option v-for="item in experienceTypeList"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="企微" v-show="false">
                    <el-select v-model="formParam.qyWeixinUserid" filterable clearable
                               placeholder="请选择企微">
                        <el-option v-for="item in qiweiUserList"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="initList">查询</el-button>
                    <el-button type="success" @click="toDetail(0,2)">新建</el-button>
                </el-form-item>
            </el-form>
        </keep-alive>
        <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
            <el-table :data="dataList" border size="mini">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <!--<el-table-column label="标识" prop="experienceId" width="80"></el-table-column>-->
                <el-table-column label="企微" prop="qwNickname" width="120"></el-table-column>
                <el-table-column label="案例名称" prop="experienceName" width="150"></el-table-column>
                <el-table-column label="案例说明" prop="experienceDesc" width="230"></el-table-column>
                <el-table-column label="案例类型" prop="typeName" width="150"></el-table-column>
                <el-table-column label="填写状态" prop="auditingStatusName" width="70">
                    <template slot-scope="scope">
                        <el-tag size="mini" type="danger" v-if="scope.row.status==0">未填写</el-tag>
                        <el-tag size="mini" type="success" v-if="scope.row.status==1">已填写</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="GMV日期" prop="gmvDate" width="110">
                    <template slot-scope="scope">
                        {{scope.row.type == 1 ? scope.row.gmvDate : '-'}}
                    </template>
                </el-table-column>
                <el-table-column label="审核状态" prop="auditingStatusName" width="70">
                    <template slot-scope="scope">
                        {{scope.row.type == 2 || scope.row.type == 3 ? scope.row.auditingStatusName : '-'}}
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="createTime" width="153"></el-table-column>
                <el-table-column label="截止时间" prop="endTime" width="153">
                    <template slot-scope="scope">
                        <div style="color: red">
                            <div>
                                {{getText(scope.row)}}
                        </div>
                            <div>
                                {{scope.row.endTime}}
                        </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="审核人" prop="auditingStatusName" width="120">
                    <template slot-scope="scope">
                        {{(scope.row.auditingStatus == 0 ? '未审核' : (scope.row.type == 2 || scope.row.type == 3) ? scope.row.auditingName : '-')}}
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="eventRemark"></el-table-column>
                <el-table-column label="操作" fixed="right" width="140">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="primary" size="mini"
                                       @click="toDetail(scope.row.experienceId,2)">详情
                            </el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>
        </tablePagination>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    export default {
        data() {
            return {
                formParam: {
                    pageSize: 10,
                    currentPage: 1,
                    type: 0,
                    experienceName: '',
                    qyWeixinUserid: '',
                    materailId: '',
                    auditing: -1,
                },
                dataList: [],
                typeList: [],
                qiweiUserList: [],
                experienceTypeList: [],
                showDialog: false,
                total: 0
            }
        },
        components: {
            defaultSec, tablePagination
        },
        mounted(){
            this.initList()
            this.getCaseTypeList()
            this.initQwUserList()
        },
        methods: {
            getCaseTypeList(){
                this.experienceTypeList = []
                this.$apiGet('lechun-cms/experience/getCaseTypeList', {}).then(res => {
                    console.log(res)
                    this.experienceTypeList = res
                    this.experienceTypeList.push({value: 0, name: '全部'})
                })
            },
            initQwUserList(){
                this.$apiGet('lechun-cms/scrmCalendar/getQwUser', {}).then(res => {
                    console.log(res)
                    this.qiweiUserList = res
                })
            },
            initList() {
                this.dataList = []
                this.$apiGet('lechun-cms/experience/getExperienceList', this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.dataList = res.list
                })
            },
            getText(row){
                let text = '';
                if (row.type == 1) {
                    if (row.status == 0) {
                        text = '填写截止'
                    } else {
                        text = '点赞截止'
                    }
                } else {
                    if (row.auditing == 0) {
                        text = '审核截止'
                    } else {
                        text = '点赞截止'
                    }
                }
                return text;
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            toDetail(experienceId, operateType){
                console.log('experienceId=' + experienceId + ",operateType=" + operateType)
                this.$router.push({
                    path: '/experienceDetail/:id/:operateType',
                    name: 'experienceDetail',
                    params: {experienceId: experienceId, operateType: operateType}
                })
            }
        }
    }
</script>

<style scoped>
    .expand-title {
        width: 70px;
        color: #444548;
        line-height: 30px;
        text-align: right;
        margin-right: 10px;
        font-weight: 600;
    }

    .expand-flex {
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
</style>